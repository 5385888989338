import React, { useState, useEffect } from 'react';
import { Search, MapPin, Star, ArrowRight, Phone, Mail, Menu, X, Globe, Instagram, Clock } from 'lucide-react';
import Contact from './components/Contact';
import Footer from './components/Footer';

const API_URL = process.env.NODE_ENV === 'production' 
  ? 'https://orlandoservicehub.com' 
  : 'http://localhost:3001';

const ServiceCard = ({ service }) => (
  <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow">
    <div className="p-6">
      <h3 className="text-xl font-semibold mb-2">{service.name}</h3>
      <p className="text-sm text-gray-600 mb-3">{service.description}</p>

      <div className="flex items-center mb-3">
        <Star className="h-4 w-4 text-yellow-400" />
        <span className="ml-1 text-gray-700">{service.rating}</span>
        <span className="ml-1 text-gray-500">({service.reviews} reviews)</span>
      </div>

      <div className="space-y-2">
        <div className="flex items-center text-gray-600">
          <Phone className="h-4 w-4 mr-2" />
          <a href={`tel:${service.phone}`} className="hover:text-blue-500">{service.phone}</a>
        </div>

        {service.website && (
          <div className="flex items-center text-gray-600">
            <Globe className="h-4 w-4 mr-2" />
            <a href={service.website} target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
              Visit Website
            </a>
          </div>
        )}

        <div className="flex items-center text-gray-600">
          <MapPin className="h-4 w-4 mr-2" />
          <span>{service.address}</span>
        </div>

        <div className="flex items-center text-gray-600">
          <Clock className="h-4 w-4 mr-2" />
          <span>{service.hours}</span>
        </div>
      </div>

      <div className="mt-4">
        <span className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
          {service.subcategory}
        </span>
      </div>
    </div>
  </div>
);

const Navbar = ({ currentPage, setCurrentPage }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <button onClick={() => setCurrentPage('home')} className="text-xl font-bold text-blue-600">
              Orlando Service Hub
            </button>
          </div>

          <div className="md:hidden flex items-center">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {isMenuOpen ? <X /> : <Menu />}
            </button>
          </div>

          <div className="hidden md:flex items-center space-x-8">
            {['home', 'about', 'contact'].map(page => (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={`${currentPage === page ? 'text-blue-600' : 'text-gray-700'} hover:text-blue-600`}
              >
                {page.charAt(0).toUpperCase() + page.slice(1)}
              </button>
            ))}
          </div>
        </div>

        {isMenuOpen && (
          <div className="md:hidden pb-4">
            <div className="flex flex-col space-y-4">
              {['home', 'about', 'contact'].map(page => (
                <button
                  key={page}
                  onClick={() => {
                    setCurrentPage(page);
                    setIsMenuOpen(false);
                  }}
                  className="text-gray-700 hover:text-blue-600 text-left"
                >
                  {page.charAt(0).toUpperCase() + page.slice(1)}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

const Home = ({ categories, services }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState(null);

  const searchAllServices = () => {
    if (!searchTerm) return [];

    let results = [];
    Object.entries(services).forEach(([category, categoryServices]) => {
      const filtered = categoryServices.filter(service =>
        service.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        service.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        service.subcategory.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (filtered.length > 0) {
        results = [...results, ...filtered];
      }
    });
    return results;
  };

  const filteredServices = activeCategory ?
    (services[activeCategory] || []).filter(service =>
      service.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      service.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      service.subcategory.toLowerCase().includes(searchTerm.toLowerCase())
    ) : searchAllServices();

  const selectedCategory = categories.find(cat => cat.name === activeCategory);

  return (
    <div className="max-w-6xl mx-auto p-6">
      {/* Hero Section */}
      <div className="text-center mb-12 bg-gradient-to-r from-blue-500 to-purple-600 text-white p-12 rounded-lg">
        <h1 className="text-4xl font-bold mb-4">Discover Orlando's Best Services</h1>
        <p className="text-xl mb-8">Your one-stop directory for local professionals and businesses</p>

        <div className="relative max-w-2xl mx-auto">
          <Search className="absolute left-3 top-3 text-gray-400" />
          <input
            type="text"
            placeholder="Search for any service..."
            className="w-full p-3 pl-12 pr-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value === '') {
                setActiveCategory(null);
              }
            }}
          />
        </div>
      </div>

      {/* Search Results */}
      {searchTerm && (
        <div className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Search Results ({filteredServices.length})
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {filteredServices.map((service, index) => (
              <ServiceCard key={index} service={service} />
            ))}
          </div>
          {filteredServices.length === 0 && (
            <p className="text-center text-gray-600 py-8">
              No services found matching "{searchTerm}". Try different keywords or browse our categories below.
            </p>
          )}
        </div>
      )}

      {/* Category View */}
      {activeCategory && !searchTerm ? (
        <div>
          {/* Back Button */}
          <button
            onClick={() => setActiveCategory(null)}
            className="flex items-center text-blue-600 hover:text-blue-800 mb-6"
          >
            <ArrowRight className="h-4 w-4 mr-2 transform rotate-180" />
            Back to Categories
          </button>

          {/* Selected Category Header */}
          <div className="flex items-center mb-8">
            <span className="text-4xl mr-4">{selectedCategory?.icon}</span>
            <div>
              <h2 className="text-2xl font-bold">{selectedCategory?.name}</h2>
              <p className="text-gray-600">{selectedCategory?.description}</p>
            </div>
          </div>

          {/* Services Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {filteredServices.map((service, index) => (
              <ServiceCard key={index} service={service} />
            ))}
          </div>
        </div>
      ) : !searchTerm && (
        /* Categories Grid */
        <>
          <h2 className="text-2xl font-bold mb-6">Browse Categories</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {categories.map((category) => (
              <div
                key={category.name}
                className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow cursor-pointer p-4"
                onClick={() => setActiveCategory(category.name)}
              >
                <div className="flex items-center justify-between mb-2">
                  <span className="text-3xl">{category.icon}</span>
                  <ArrowRight className="text-gray-400" />
                </div>
                <h3 className="text-lg font-semibold">{category.name}</h3>
                <p className="text-sm text-gray-600 mt-1">{category.description}</p>
                <div className="mt-2 flex flex-wrap gap-1">
                  {category.subcategories.slice(0, 2).map((sub) => (
                    <span key={sub} className="text-xs bg-gray-100 px-2 py-1 rounded-full">
                      {sub}
                    </span>
                  ))}
                  {category.subcategories.length > 2 && (
                    <span className="text-xs text-blue-500">
                      +{category.subcategories.length - 2} more
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const About = () => (
  <div className="max-w-4xl mx-auto p-6">
    <h1 className="text-3xl font-bold mb-6">About Orlando Service Hub</h1>
    <div className="prose">
      <p className="mb-4">
        Orlando Service Hub is your comprehensive directory for finding trusted local service providers
        in the greater Orlando area. Our platform connects residents with qualified professionals
        across various categories, from healthcare to home services.
      </p>
      <p className="mb-4">
        Our mission is to make it easy for Orlando residents to discover, compare, and connect
        with the best local service providers. We carefully verify each listing to ensure quality
        and reliability.
      </p>
      <h2 className="text-2xl font-bold mt-8 mb-4">Why Choose Us</h2>
      <ul className="list-disc pl-6 mb-6">
        <li>Comprehensive directory of local services</li>
        <li>Verified service providers</li>
        <li>Easy-to-use search and filters</li>
        <li>Customer reviews and ratings</li>
        <li>Direct contact with providers</li>
      </ul>
      <h2 className="text-2xl font-bold mt-8 mb-4">We Go Beyond the Usual Categories</h2>
      <p className="mb-4">
        Orlando Service Hub also highlights services that are often underserved on larger platforms,
        such as small handyman services, personal trainers, freelance creatives, pet care providers,
        mobile mechanics, tutors, and event planners. This ensures even the smallest local businesses
        get the visibility they deserve and Orlando residents have access to a truly diverse range
        of service providers.
      </p>
    </div>
  </div>
);


const OrlandoServices = () => {
  const [currentPage, setCurrentPage] = useState('home');
  const [services, setServices] = useState({});

  const categories = [
    {
      name: 'Medical',
      icon: '👨‍⚕️',
      providers: 245,
      description: 'Find doctors and specialist clinics',
      subcategories: ['Primary Care', 'Specialists', 'Urgent Care', 'Walk-in Clinics']
    },
    {
      name: 'Food & Dining',
      icon: '🍽️',
      providers: 312,
      description: 'Explore food options, from restaurants to delivery services',
      subcategories: ['Restaurants', 'Food Delivery', 'Catering', 'Food Trucks']
    },
    {
      name: 'Beauty & Wellness',
      icon: '💆‍♀️',
      providers: 189,
      description: 'Discover beauty salons, spas and beauty clinics',
      subcategories: ['Hair Salons', 'Spas', 'Beauty Clinics', 'Nail Salons']
    },
    {
      name: 'Well-being',
      icon: '🧘‍♀️',
      providers: 156,
      description: 'Find gyms, yoga and pilates centers',
      subcategories: ['Gyms', 'Yoga Studios', 'Pilates', 'Personal Trainers']
    },
    {
      name: 'Retail',
      icon: '🛍️',
      providers: 278,
      description: 'Discover stores and shopping centers',
      subcategories: ['Shopping Centers', 'Boutiques', 'Specialty Stores']
    },
    {
      name: 'Education',
      icon: '📚',
      providers: 167,
      description: 'Schools, courses and educational centers',
      subcategories: ['Schools', 'Tutoring', 'Language Centers', 'Professional Training']
    },
    {
      name: 'Events',
      icon: '🎭',
      providers: 134,
      description: 'Check out the best events and entertainment options',
      subcategories: ['Venues', 'Event Planners', 'Entertainment', 'Tickets']
    },
    {
      name: 'Maintenance',
      icon: '🔧',
      providers: 223,
      description: 'Residential maintenance and repair services',
      subcategories: ['Home Repair', 'Plumbing', 'Electrical', 'HVAC']
    },
    {
      name: 'Technology',
      icon: '💻',
      providers: 145,
      description: 'Find technology services and technical support',
      subcategories: ['IT Support', 'Web Development', 'Device Repair', 'Digital Services']
    },
    {
      name: 'Transportation',
      icon: '🚗',
      providers: 178,
      description: 'Transportation and automotive services',
      subcategories: ['Auto Repair', 'Car Wash', 'Rideshare', 'Rental Services']
    },
    {
      name: 'Financial',
      icon: '💰',
      providers: 167,
      description: 'Financial services and consulting',
      subcategories: ['Banking', 'Investment', 'Tax Services', 'Insurance']
    },
    {
      name: 'Pets',
      icon: '🐾',
      providers: 134,
      description: 'Pet services and animal care',
      subcategories: ['Veterinarians', 'Pet Grooming', 'Pet Boarding', 'Pet Supplies']
    },
    {
      name: 'Professionals',
      icon: '👔',
      providers: 256,
      description: 'Find lawyers, accountants and other professionals',
      subcategories: ['Legal Services', 'Accounting', 'Consulting', 'Real Estate']
    }
  ];

  useEffect(() => {
    const loadServices = async () => {
      try {
        const medicalData = await import('./data/medical-services.json');
        const foodData = await import('./data/food-services.json');
        const beautyData = await import('./data/beauty-services.json');
        const wellbeingData = await import('./data/wellbeing-services.json');
        const retailData = await import('./data/retail-services.json');
        const educationData = await import('./data/education-services.json');
        const eventsData = await import('./data/events-services.json');
        const maintenanceData = await import('./data/maintenance-services.json');
        const technologyData = await import('./data/technology-services.json');
        const transportationData = await import('./data/transportation-services.json');
        const financialData = await import('./data/financial-services.json');
        const petsData = await import('./data/pets-services.json');
        const professionalData = await import('./data/professional-services.json');

        setServices({
          'Medical': medicalData.services,
          'Food & Dining': foodData.services,
          'Beauty & Wellness': beautyData.services,
          'Well-being': wellbeingData.services,
          'Retail': retailData.services,
          'Education': educationData.services,
          'Events': eventsData.services,
          'Maintenance': maintenanceData.services,
          'Technology': technologyData.services,
          'Transportation': transportationData.services,
          'Financial': financialData.services,
          'Pets': petsData.services,
          'Professional': professionalData.services
        });
      } catch (error) {
        console.error('Error loading services:', error);
      }
    };

    loadServices();
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Navbar currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <div className="flex-grow">
      {currentPage === 'home' && <Home categories={categories} services={services} />}
      {currentPage === 'about' && <About />}
      {currentPage === 'contact' && <Contact />}
    </div>
    <Footer />
  </div>
  );
};

export default OrlandoServices;
